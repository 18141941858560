import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import { Helmet } from "react-helmet";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet titleTemplate="%s - Chetan Bhat">
          <title>Notes</title>
          <meta
            name="description"
            content="My latest notes - Chetan Bhat"
          />
        </Helmet>
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section content">
                  <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                    Latest Notes
                  </h2>
                  <BlogRoll />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
